import {
    AgeGroupReportRecord,
    getChartColor,
} from "models/kindergartenAgeGroupReport";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { DATEPICKER_SHORT_MONTH_YEAR_FORMAT } from "models/datepicker";
import { shadeColor } from "models/general";
import styles from "./AgeGroupRegistrationsBarChart.module.scss";

export interface AgeGroupRegistrationsBarChartProps {
    data: AgeGroupReportRecord;
}

// pass idx to stop random colors
const getColors = (existingColors: string[], idx: number) => {
    let newColor = getChartColor(idx);
    while (existingColors.includes(newColor)) {
        newColor = getChartColor(idx + 1);
        idx += 1;
    }
    return [newColor, shadeColor(newColor, 25)];
};

const commonOptions = {
    barThickness: 20,
    maxBarThickness: 60,
    borderWidth: 2,
    minBarLength: 2,
};
export const AgeGroupRegistrationsBarChart: React.FC<
    AgeGroupRegistrationsBarChartProps
> = ({ data }) => {
    const { t } = useTranslation();
    const processedData = useMemo(() => {
        const hasRegistrations =
            data.AgeGroupsDetail.reduce(
                (total, newVal) => total + newVal.YearRegistrations,
                0
            ) > 0;

        const dataToUse = data.AgeGroupsDetail;
        const colorsArrToUse = data.AgeGroupsDetail.map((x, idx) =>
            getColors([], x.AgeGroupYear)
        );
        const idxArray = dataToUse.map((x) =>
            data.AgeGroupsDetail.findIndex(
                (y) => y.AgeGroupYear == x.AgeGroupYear
            )
        );
        return hasRegistrations
            ? {
                  labels: dataToUse.map((x) => x.AgeGroupYear.toString()),
                  datasets: [
                      {
                          label: t("ageGroupPlanning.monthlyStats", {
                              month: data.Month.format(
                                  DATEPICKER_SHORT_MONTH_YEAR_FORMAT
                              ),
                          }),
                          data: dataToUse.map((x) => x.Registrations),
                          backgroundColor: idxArray.map(
                              (i) => colorsArrToUse[i][1]
                          ),
                          borderColor: idxArray.map(
                              (i) => colorsArrToUse[i][0]
                          ),
                          ...commonOptions,
                      },
                      {
                          label: t("ageGroupPlanning.yearlyStats", {
                              year: data.Month.year(),
                          }),
                          data: dataToUse.map((x) => x.YearRegistrations),
                          backgroundColor: idxArray.map(
                              (i) => colorsArrToUse[i][1]
                          ),
                          borderColor: idxArray.map(
                              (i) => colorsArrToUse[i][0]
                          ),
                          ...commonOptions,
                      },
                  ],
              }
            : null;
    }, [data.AgeGroupsDetail, t]);

    console.log("data", processedData);
    return (
        <div className={styles.root}>
            <div className={styles.title}>
                {t("ageGroupPlanning.registrationStats").toString()}
            </div>
            {processedData ? (
                <div className={styles.chart}>
                    <Bar
                        data={processedData}
                        options={{
                            indexAxis: "y",
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    axis: "x",
                                    grid: {
                                        display: true,
                                    },
                                    title: {
                                        display: true,
                                        text: t(
                                            "ageGroupPlanning.kidsCount"
                                        ).toString(),
                                    },
                                    ticks: {
                                        stepSize: 1,
                                        maxTicksLimit: 10,
                                    },
                                },
                                y: {
                                    grid: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: t(
                                            "ageGroupPlanning.ageGroup"
                                        ).toString(),
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : (
                <div className={styles.noRegistration}>
                    {t("ageGroupPlanning.noYearRegistrations", {
                        year: data.Month.year(),
                    })}
                </div>
            )}
        </div>
    );
};

export default AgeGroupRegistrationsBarChart;
